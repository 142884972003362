/* CSS */
import "./src/main.scss"
import "./src/scss/custom-aos.scss" /* import seperately to protect against purge css */

/* Intersection Observer Polyfill */
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")

  }
}
